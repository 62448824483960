// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-list-js": () => import("../src/templates/list.js" /* webpackChunkName: "component---src-templates-list-js" */),
  "component---src-templates-category-list-js": () => import("../src/templates/categoryList.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-coupon-js": () => import("../src/pages/account/coupon.js" /* webpackChunkName: "component---src-pages-account-coupon-js" */),
  "component---src-pages-account-forgot-js": () => import("../src/pages/account/forgot.js" /* webpackChunkName: "component---src-pages-account-forgot-js" */),
  "component---src-pages-account-login-coupon-index-js": () => import("../src/pages/account/login/coupon/index.js" /* webpackChunkName: "component---src-pages-account-login-coupon-index-js" */),
  "component---src-pages-account-login-coupon-success-js": () => import("../src/pages/account/login/coupon/success.js" /* webpackChunkName: "component---src-pages-account-login-coupon-success-js" */),
  "component---src-pages-account-login-index-js": () => import("../src/pages/account/login/index.js" /* webpackChunkName: "component---src-pages-account-login-index-js" */),
  "component---src-pages-account-platform-js": () => import("../src/pages/account/platform.js" /* webpackChunkName: "component---src-pages-account-platform-js" */),
  "component---src-pages-account-signup-js": () => import("../src/pages/account/signup.js" /* webpackChunkName: "component---src-pages-account-signup-js" */),
  "component---src-pages-account-video-demo-js": () => import("../src/pages/account/videoDemo.js" /* webpackChunkName: "component---src-pages-account-video-demo-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-js": () => import("../src/pages/latest.js" /* webpackChunkName: "component---src-pages-latest-js" */),
  "component---src-pages-ringindex-js": () => import("../src/pages/ringindex.js" /* webpackChunkName: "component---src-pages-ringindex-js" */),
  "component---src-pages-shopping-cart-js": () => import("../src/pages/shopping/cart.js" /* webpackChunkName: "component---src-pages-shopping-cart-js" */),
  "component---src-pages-shopping-order-js": () => import("../src/pages/shopping/order.js" /* webpackChunkName: "component---src-pages-shopping-order-js" */),
  "component---src-pages-shopping-order-details-js": () => import("../src/pages/shopping/orderDetails.js" /* webpackChunkName: "component---src-pages-shopping-order-details-js" */),
  "component---src-pages-shopping-payment-js": () => import("../src/pages/shopping/payment.js" /* webpackChunkName: "component---src-pages-shopping-payment-js" */),
  "component---src-pages-shopping-progress-js": () => import("../src/pages/shopping/progress.js" /* webpackChunkName: "component---src-pages-shopping-progress-js" */)
}

